import { Link, navigate } from 'gatsby';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';
import { ParàmetresDAlta, usaUsuari, Usuari } from './Usuari';
import { FabricaFormulari } from './Formulari';
import _ from 'lodash';
import { Carregant } from './Carregant';
import { Opcions, usaOpcions } from './Opcions';
import { Col, Placeholder, Row } from 'react-bootstrap';

const { Formulari, Camp, Casella, Selecció, BotóDEnviar } = FabricaFormulari<ParàmetresDAlta>();

export const Registrar: FunctionComponent<{}> = () => {
  const { usuari, registra } = usaUsuari();
  const { opcions, opcióPredeterminada } = usaOpcions();
  const [registrat, posaRegistrat] = useState(false);

  if (!registrat && usuari) {
    console.log('Ja tenim un usuari... anant a la pàgina de socis.')
    void navigate('/socis');
  }

  if (usuari === undefined) return <Carregant/>;

  async function enEnviar(paràmetres: ParàmetresDAlta) {
    posaRegistrat(true);
    await registra(paràmetres);
    void navigate('/socis/pagar');
    return {};
  }

  return (
    <Formulari envia={enEnviar} valorsInicials={{ permís_per_enviar_correus_electrònics: 'no'}}>
      <Stack gap={3}>
        <Form.Group>
          <Form.Label>Email</Form.Label>
          <Camp nom="correu_electrònic" obligatori tipus="email" ocupant="Email"/>
          <Form.Text className="text-muted">
            Llevat que ens ho demanis, l’usarem només per a la gestió de la teva quota.
          </Form.Text>
        </Form.Group>
        <Form.Group>
          <Form.Label>Contrasenya</Form.Label>
          <Camp nom="contrasenya" obligatori tipus="password" ocupant="Contrasenya"/>
        </Form.Group>
        <Form.Group>
          <Form.Label>Nom i cognoms</Form.Label>
          <Camp nom="nom" obligatori/>
        </Form.Group>
        <Form.Group>
          <Form.Label>DNI, NIE, o número de passaport</Form.Label>
          <Camp nom="identificació" obligatori/>
        </Form.Group>
        <Form.Group>
          <Form.Label>Telèfon</Form.Label>
          <Camp nom="telèfon" obligatori tipus="tel"/>
        </Form.Group>
        <Form.Group>
          <Form.Label>Tria una quota</Form.Label>
          {opcions === undefined
            ? <Placeholder as="span" animation="glow"><Placeholder className="w-100" style={{ height: '36px' }}/></Placeholder>
            : <Selecció nom='id_opció' valorInicial={opcióPredeterminada?.id}>
                <Opcions opcions={opcions}/>
              </Selecció>
          }
        </Form.Group>
        <Form.Group>
          <Form.Label>Codi postal</Form.Label>
          <Camp nom="codi_postal"/>
          <Form.Text className="text-muted">
            (opcional) L'usarem només amb finalitats estadístiques.
          </Form.Text>
        </Form.Group>
        <Form.Group>
          <Casella nom="permís_per_enviar_correus_electrònics"
            text="Sí, vull que m'envieu de tant en tant missatges amb informació rellevant sobre el Casal i les seves activitats."
          />
        </Form.Group>
        <Form.Group className="d-flex flex-column flex-sm-row">
          <BotóDEnviar className="mt-2">
            Seguim!
          </BotóDEnviar>
          <Form.Text className="text-muted ms-auto text-end">
            En premer aquest botó acceptes que es tractin les teves dades conforme a la <Link to="/privacitat">Política de privacitat</Link>.
          </Form.Text>
        </Form.Group>
      </Stack>
    </Formulari>
  );
};

const PAÏSOS = {
  AF: 'Afganistan',
  AX: 'Aland',
  AL: 'Albània',
  DE: 'Alemanya',
  DZ: 'Algèria',
  AD: 'Andorra',
  AO: 'Angola',
  AI: 'Anguilla',
  AQ: 'Antàrtida',
  AG: 'Antigua i Barbuda',
  SA: 'Aràbia Saudí',
  AR: 'Argentina',
  AM: 'Armènia',
  AW: 'Aruba',
  AU: 'Austràlia',
  AT: 'Àustria',
  AZ: 'Azerbaidjan',
  BS: 'Bahames, les',
  BH: 'Bahrain',
  BD: 'Bangladesh',
  BB: 'Barbados',
  BY: 'Belarús',
  BE: 'Bèlgica',
  BZ: 'Belize',
  BJ: 'Benín',
  BM: 'Bermudes',
  BT: 'Bhutan',
  BO: 'Bolívia',
  BQ: 'Bonaire, Sint Eustatius i Saba',
  BA: 'Bòsnia i Hercegovina',
  BW: 'Botswana',
  BR: 'Brasil',
  BN: 'Brunei',
  BG: 'Bulgària',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  KH: 'Cambodja',
  CM: 'Camerun',
  CA: 'Canadà',
  CV: 'Cap Verd',
  CO: 'Colòmbia',
  KM: 'Comores, les',
  CG: 'Congo',
  KP: 'Corea del Nord',
  KR: 'Corea del Sud',
  CI: 'Costa d\'Ivori',
  CR: 'Costa Rica',
  HR: 'Croàcia',
  CU: 'Cuba',
  CW: 'Curaçao',
  DK: 'Dinamarca',
  DJ: 'Djibouti',
  DM: 'Dominica',
  EG: 'Egipte',
  SV: 'El Salvador',
  AE: 'Emirats Àrabs Units, els',
  EC: 'Equador',
  ER: 'Eritrea',
  SK: 'Eslovàquia',
  SI: 'Eslovènia',
  ES: 'Espanya',
  US: 'Estats Units, els',
  EE: 'Estònia',
  SZ: 'Eswatini',
  ET: 'Etiòpia',
  FO: 'Fèroe',
  FJ: 'Fiji',
  PH: 'Filipines',
  FI: 'Finlàndia',
  FR: 'França',
  GA: 'Gabon',
  GM: 'Gàmbia',
  GE: 'Geòrgia',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GR: 'Grècia',
  GD: 'Grenada',
  GL: 'Groenlàndia',
  GP: 'Guadalupe',
  GF: 'Guaiana Francesa',
  GU: 'Guam',
  GT: 'Guatemala',
  GG: 'Guernsey',
  GN: 'Guinea',
  GQ: 'Guinea Equatorial',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HT: 'Haití',
  HN: 'Hondures',
  HK: 'Hong Kong, Xina',
  HU: 'Hongria',
  YE: 'Iemen, el',
  BV: 'Illa Bouvet',
  CX: 'Illa Christmas',
  IM: 'Illa de Man',
  NF: 'Illa Norfolk',
  KY: 'Illes Caiman',
  CC: 'Illes Cocos (Keeling)',
  CK: 'Illes Cook',
  FK: 'Illes Falkland (Malvines)',
  GS: 'Illes Geòrgia del Sud i Sandwich del Sud',
  HM: 'Illes Heard i McDonald',
  MP: 'Illes Marianes del Nord',
  MH: 'Illes Marshall',
  UM: 'Illes Menors Allunyades dels Estats Units',
  SB: 'Illes Salomó',
  TC: 'Illes Turks i Caicos',
  VG: 'Illes Verges Britàniques',
  VI: 'Illes Verges dels Estats Units',
  IN: 'Índia',
  ID: 'Indonèsia',
  IR: 'Iran',
  IQ: 'Iraq, l\'',
  IE: 'Irlanda',
  IS: 'Islàndia',
  IL: 'Israel',
  IT: 'Itàlia',
  JM: 'Jamaica',
  JP: 'Japó',
  JE: 'Jersey',
  JO: 'Jordània',
  KZ: 'Kazakhstan',
  KE: 'Kenya',
  KG: 'Kirguizstan',
  KI: 'Kiribati',
  KW: 'Kuwait',
  LA: 'Lao',
  LS: 'Lesotho',
  LV: 'Letònia',
  LB: 'Líban',
  LR: 'Libèria',
  LY: 'Líbia',
  LI: 'Liechtenstein',
  LT: 'Lituània',
  LU: 'Luxemburg',
  MO: 'Macao, Xina',
  MK: 'Macedònia del Nord',
  MG: 'Madagascar',
  MY: 'Malàisia',
  MW: 'Malawi',
  MV: 'Maldives',
  ML: 'Mali',
  MT: 'Malta',
  MA: 'Marroc, el',
  MQ: 'Martinica',
  MU: 'Maurici',
  MR: 'Mauritània',
  YT: 'Mayotte',
  MX: 'Mèxic',
  FM: 'Micronèsia',
  MZ: 'Moçambic',
  MD: 'Moldàvia',
  MC: 'Mònaco',
  MN: 'Mongòlia',
  ME: 'Montenegro',
  MS: 'Montserrat',
  MM: 'Myanmar',
  NA: 'Namíbia',
  NR: 'Nauru',
  NP: 'Nepal',
  NI: 'Nicaragua',
  NE: 'Níger',
  NG: 'Nigèria',
  NU: 'Niue',
  NO: 'Noruega',
  NC: 'Nova Caledònia',
  NZ: 'Nova Zelanda',
  OM: 'Oman',
  NL: 'Països Baixos',
  PK: 'Pakistan',
  PW: 'Palau',
  PS: 'Palestina',
  PA: 'Panamà',
  PG: 'Papua Nova Guinea',
  PY: 'Paraguai',
  PE: 'Perú',
  PN: 'Pitcairn',
  PF: 'Polinèsia Francesa',
  PL: 'Polònia',
  PT: 'Portugal',
  PR: 'Puerto Rico',
  QA: 'Qatar',
  GB: 'Regne Unit',
  CF: 'República Centreafricana',
  CD: 'República Democràtica del Congo',
  DO: 'República Dominicana',
  RE: 'Reunió, la',
  RO: 'Romania',
  RW: 'Ruanda',
  RU: 'Rússia',
  EH: 'Sàhara Occidental',
  SH: 'Saint Helena, Ascenció i Tristan da Cunha',
  KN: 'Saint Kitts i Nevis',
  LC: 'Saint Lucia',
  VC: 'Saint Vincent i les Grenadines',
  BL: 'Saint-Barthélemy',
  MF: 'Saint-Martin',
  PM: 'Saint-Pierre-et-Miquelon',
  WS: 'Samoa',
  AS: 'Samoa Americana',
  SM: 'San Marino',
  VA: 'Santa Seu',
  ST: 'São Tomé i Príncipe',
  SN: 'Senegal',
  RS: 'Sèrbia',
  SC: 'Seychelles',
  SL: 'Sierra Leone',
  SG: 'Singapur',
  SX: 'Sint Maarten',
  SY: 'Síria',
  SO: 'Somàlia',
  LK: 'Sri Lanka',
  ZA: 'Sud-àfrica',
  SS: 'Sudan del Sud, el',
  SD: 'Sudan, el',
  SE: 'Suècia',
  CH: 'Suïssa',
  SR: 'Surinam',
  SJ: 'Svalbard i Jan Mayen',
  TJ: 'Tadjikistan',
  TH: 'Tailàndia',
  TW: 'Taiwan',
  TZ: 'Tanzània',
  TF: 'Terres Australs i Antàrtiques Franceses',
  IO: 'Territori Britànic de l\'Oceà Índic',
  TL: 'Timor-Leste',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinidad i Tobago',
  TN: 'Tunísia',
  TM: 'Turkmenistan',
  TR: 'Turquia',
  TV: 'Tuvalu',
  TD: 'Txad',
  CZ: 'Txèquia',
  UA: 'Ucraïna',
  UG: 'Uganda',
  UY: 'Uruguai',
  UZ: 'Uzbekistan',
  VU: 'Vanuatu',
  VE: 'Veneçuela',
  VN: 'Vietnam',
  WF: 'Wallis i Futuna',
  CL: 'Xile',
  CN: 'Xina',
  CY: 'Xipre',
  ZM: 'Zàmbia',
  ZW: 'Zimbàbue',
}